@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --focus-color: theme(colors.brand.red.DEFAULT);
  }

  h1 {
    @apply font-h1;
  }

  h2 {
    @apply font-h2;
  }

  h3 {
    @apply font-h3;
  }

  h4 {
    @apply font-h4;
  }

  h5 {
    @apply font-h5;
  }

  p {
    @apply font-p;
  }

  b,
  strong {
    @apply font-medium;
  }
}

@layer components {
  .container {
    @apply mx-auto max-w-[theme(screens.container)];
  }

  .font-h1 {
    @apply font-sans text-2xl font-medium leading-none tracking-normal md:text-3xl;
  }

  .font-h2 {
    @apply font-sans text-xl font-medium leading-none tracking-normal md:text-2xl;
  }

  .font-h3 {
    @apply font-sans text-lg font-medium leading-none tracking-normal md:text-xl;
  }

  .font-h4 {
    @apply font-sans text-lg font-medium leading-none tracking-normal md:text-lg;
  }

  .font-h5 {
    @apply font-sans text-base font-medium leading-none tracking-normal md:text-base;
  }

  .font-button {
    @apply font-sans font-normal leading-none tracking-normal;
  }

  .font-p {
    @apply font-sans font-normal leading-snug tracking-normal last:mb-0;
  }
}

@layer utilities {
  .focus-border {
    /* stylelint-disable selector-class-pattern */
    &:has([data-focus-visible]),
    &[data-focus-visible],
    .group\/focus:has([data-focus-visible]) &,
    .group\/focus[data-focus-visible] & {
      @apply relative border border-[--focus-color];
    }
    /* stylelint-enable selector-class-pattern */

    &[data-disabled],
    &:disabled {
      @apply border-none;
    }

    &[data-focused] {
      @apply outline-none;
    }
  }

  .focus-ring {
    /* stylelint-disable selector-class-pattern */
    &:has([data-focus-visible]),
    &[data-focus-visible],
    .group\/focus:has([data-focus-visible]) &,
    .group\/focus[data-focus-visible] & {
      @apply ring-1 ring-[--focus-color];
    }
    /* stylelint-enable selector-class-pattern */

    &[data-disabled],
    &:disabled {
      @apply ring-0;
    }

    &[data-focused] {
      @apply outline-none;
    }
  }

  .no-focus {
    @apply outline-none;
  }

  .no-scrollbar {
    scrollbar-width: none;

    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
